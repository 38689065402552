import React from 'react';
import {
  EditView,
  SimpleForm,
  TextField,
  EditContextProvider,
  useEditController,
  DateField,
  ArrayInput,
  TextInput,
  SaveButton,
  Toolbar,
  BooleanInput,
  required,
  SimpleFormIterator,
  NumberInput,
  FormDataConsumer,
} from 'react-admin';
import { makeStyles } from '@mui/styles';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const CustomToolbar = () => (
  <Toolbar classes={useStyles()}>
    <SaveButton />
  </Toolbar>
);

const BrokerEdit = () => {
  const controllerProps = useEditController({
    mutationMode: 'pessimistic',
    redirect: false,
  });
  const { record } = controllerProps;

  return (
    <EditContextProvider value={controllerProps}>
      <EditView>
        <SimpleForm
          toolbar={<CustomToolbar />}
        >
          <BooleanInput source="isActive" helperText="If not active, the system will not allow the Broker to create applications" />
          <SimpleShowLabeled>
            <TextField source="utmSource" label="UTM Source" validate={required()} />
          </SimpleShowLabeled>
          <SimpleShowLabeled>
            <DateField source="createdAt" />
          </SimpleShowLabeled>
          <TextInput readOnly={record && !record?.isActive} source="name" validate={required()} />
          <BooleanInput source="requiresBankDataForBid" />
          <ArrayInput source="scoreInterestMatrix.aboveThreshold" label="Score-Interest Matrix above threshold">
            <SimpleFormIterator inline>
              <FormDataConsumer>
                {({ scopedFormData, getSource }) => (
                  <>
                    <NumberInput
                      source={getSource('maximumUCScore')}
                      label="Maximum UC Score"
                      record={scopedFormData}
                      readOnly={record && !record?.isActive}
                      parse={(v) => v}
                    />
                    <NumberInput
                      source={getSource('interest')}
                      label="Interest"
                      record={scopedFormData}
                      readOnly={record && !record?.isActive}
                      parse={(v) => v}
                    />
                  </>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </EditView>
    </EditContextProvider>
  );
};

export default BrokerEdit;
