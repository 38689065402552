import React from 'react';

import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  EditView,
  FunctionField,
  NumberField,
  ReferenceField,
  TabbedForm,
  FormTab,
  TextField,
  ReferenceInput,
  ShowButton,
  ReferenceManyField,
  useEditController,
  EditContextProvider,
  Pagination,
  TextInput,
  useRecordContext,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import SelectInput from '../input/SelectInput';
import SelectArrayInput from '../input/SelectArrayInput';
import AccountStatusChip from './AccountStatusChip';
import AddDrawdownButton from '../utils/AddDrawdownButton';
import TerminateDrawdownButton from '../utils/TerminateDrawdownButton';
import AmountDateField from '../utils/AmountDateField';
import CancelAccountButton from '../utils/CancelAccountButton';
import FreezeAccountButton from '../utils/FreezeAccountButton';
import DebtSoldButton from '../utils/DebtSoldButton';
import DrawdownTerms from '../utils/DrawdownTerms';
import MarkAsFraud from '../utils/MarkAsFraud';
import MoneyField from '../utils/MoneyField';
import PageTitle from '../utils/PageTitle';
import PaybackButton from '../utils/PaybackButton';
import RefinanceButton from '../utils/RefinanceButton';
import UpdateNominalRateButton from '../utils/UpdateNominalRateButton';
import SolvingAmount from '../utils/SolvingAmount';
import TransactionFields from '../utils/TransactionFields';
import InsuranceTab from '../utils/InsuranceTab';
import AccountCategoryChip from '../utils/AccountCategoryChip';
import OpenCustomerAgreement from '../utils/OpenCustomerAgreement';
import ExtraAmortisationRequestButton from '../utils/ExtraAmortisationRequestButton';
import ZeroAccountButton from '../utils/ZeroAccountButton';

import { accountNumberFormat } from '../../utils/formatters';

import AccountHoldersFields from './AccountHoldersFields';
import ReportLateInterestButton from '../utils/ReportLateInterestButton';
import InvoiceFields from '../utils/InvoiceFields';
import DownloadWhitelistedPayerDocumentButton from '../utils/DownloadWhitelistedPayerDocumentButton';
import DownloadEARDocumentButton from '../utils/DownloadEARDocumentButton';
import AccountUtmSourceChip from './AccountUtmSourceChip';
import { useConstantContext } from '../utils/ConstantsContext';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

const AccountTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <span style={{ margin: '0 1rem' }}>Account: {record.id}</span>
      <AccountStatusChip
        status={record.status}
        passedInvoicesPayed={record.passedInvoicesPayed}
      />
      <AccountCategoryChip accountType={record.accountType} />
      <AccountUtmSourceChip trackingData={record.trackingData} />
    </div>
  );
};

const getRecord = (props) => props.record || {};

const getAccountHolderIds = (props) => (getRecord(props).accountHolders || [])
  .map((accountHolder) => accountHolder._id);

const getBankAccountFilter = (props) => ({
  [getRecord(props).accountHolders[0].holderType]: getAccountHolderIds(props),
});

const AccountEdit = () => {
  const { constants, choices } = useConstantContext();
  const { categoryLabel } = constants;
  const { invoiceMedia, accountFlags } = choices;

  const controllerProps = useEditController({
    mutationMode: 'pessimistic',
    redirect: false,
  });

  if (controllerProps.isLoading) return null;
  return (
    <EditContextProvider value={controllerProps}>
      <EditView title={<AccountTitle />}>
        <TabbedForm>
          <FormTab label="Account Details">
            <PageTitle title="Account | Edit" />
            <SimpleShowLabeled>
              <AccountHoldersFields label="Account holders" />
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <TextField source="accountType" />
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <DateField source="createdAt" />
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <TextField label="Public id" source="publicId" />
            </SimpleShowLabeled>
            {getRecord(controllerProps).terms && getRecord(controllerProps).terms.accountId ? (
              <SimpleShowLabeled>
                <ReferenceField
                  reference="account"
                  source="terms.accountId"
                  label="Insured Account"
                >
                  <TextField source="publicId" />
                </ReferenceField>
              </SimpleShowLabeled>
            ) : null}
            {getRecord(controllerProps).accountHolders && (
              <ReferenceInput
                label="Bank account Payout"
                source="bankAccountIdPayout"
                reference="bankAccount"
                filter={getBankAccountFilter(controllerProps)}
              >
                <SelectInput optionText={(record) => record && `${record.name || ''} ${accountNumberFormat(record.bankAccountType, record.accountNumber, record.clearingNumber)}`} />
              </ReferenceInput>
            )}
            {getRecord(controllerProps).accountHolders && (
              <ReferenceInput
                label="Bank account Payin"
                source="bankAccountIdPayin"
                reference="bankAccount"
                allowEmpty
                filter={{
                  ...getBankAccountFilter(controllerProps),
                  eligibleForAutogiroOnly: true,
                }}
              >
                <SelectInput optionText={(record) => record && `${record.name || ''} ${accountNumberFormat(record.bankAccountType, record.accountNumber, record.clearingNumber)}`} />
              </ReferenceInput>
            )}
            <SelectInput source="invoiceMedium" choices={invoiceMedia} />
            {getRecord(controllerProps).terms
            && getRecord(controllerProps).terms.invoicingEmail && (
              <TextInput label="Invoicing Email" source="terms.invoicingEmail" />
            )}
            <CancelAccountButton />
            <FreezeAccountButton />
            <MarkAsFraud />
            {controllerProps.record?.status === 'cancelled' && controllerProps.record?.todaySolvingAmount > 1
              && <DebtSoldButton />}
            <PaybackButton source="id" />
            <RefinanceButton
              holderId={getAccountHolderIds(controllerProps)[0]}
              selectedAccounts={[{
                id: getRecord(controllerProps).id,
                publicId: getRecord(controllerProps).publicId,
                todaySolvingAmount: getRecord(controllerProps).todaySolvingAmount,
                status: getRecord(controllerProps).status,
              }]}
            />
            <TerminateDrawdownButton />
            <AddDrawdownButton />
            <OpenCustomerAgreement />
            {(controllerProps.record?.status === 'cancelled' || controllerProps.record?.status === 'sold') && (
              <SimpleShowLabeled>
                <AmountDateField source="cancellation" label="Cancellation" />
              </SimpleShowLabeled>
            )}
            {(controllerProps.record?.status === 'cancelled' || controllerProps.record?.status === 'sold') && (
              <SimpleShowLabeled>
                <DateField source="sentToDebtCollectionAt" label="Sent to Debt Collection Date" />
              </SimpleShowLabeled>
            )}
            {controllerProps.record?.debtCollector && (
              <SimpleShowLabeled>
                <TextField source="debtCollector" label="Debt collector" />
              </SimpleShowLabeled>
            )}
            {controllerProps.record?.status === 'sold' && (
              <SimpleShowLabeled>
                <AmountDateField source="debtSold" label="Debt sold" />
              </SimpleShowLabeled>
            )}
            <SimpleShowLabeled>
              <BooleanField label="Performing Customer" source="isPerforming" />
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <BooleanField label="Account Eligible For Cancellation" source="isEligibleForCancellation" />
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <TextField source="ocrNumber" label="OCR Number" />
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <TextField source="paybackAccount" label="Payback Account" />
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <MoneyField label="Remaining Capital" source="remainingCapitalAmount" />
            </SimpleShowLabeled>
            <ZeroAccountButton />
            <SolvingAmount />
            <SimpleShowLabeled>
              <MoneyField source="currentTerms.nominalRate" label="Nominal rate" />
            </SimpleShowLabeled>
            <DrawdownTerms />
            {controllerProps.record?.currentTerms.creditLimit && (
              <SimpleShowLabeled>
                <NumberField
                  source="currentTerms.creditLimit"
                  label="Limit"
                  options={{ style: 'currency', currency: 'SEK' }}
                />
              </SimpleShowLabeled>
            )}
            {controllerProps.record?.pledgedTo && (
              <SimpleShowLabeled>
                <ReferenceField label="Pledged to" source="pledgedTo" reference="organisation">
                  <TextField source="id" />
                </ReferenceField>
              </SimpleShowLabeled>
            )}
            <SelectArrayInput
              label="Flags"
              source="flags"
              choices={accountFlags}
            />
            <SimpleShowLabeled>
              <InvoiceFields label="Invoices" />
            </SimpleShowLabeled>
            <Box display="flex" gap={8}>
              <SimpleShowLabeled>
                <NumberField label="Estimated remaining payments" source="estimatedRepaymentTime" />
              </SimpleShowLabeled>
              <SimpleShowLabeled>
                <FunctionField
                  label="Number of invoices"
                  render={(record) => (
                    <Typography>{record.invoices.length}</Typography>
                  )}
                />
              </SimpleShowLabeled>
            </Box>
            <ExtraAmortisationRequestButton />
            <SimpleShowLabeled>
              <ArrayField label="Extra Amortisation Request" source="extraAmortisationRequests">
                <Datagrid bulkActionButtons={null}>
                  <ReferenceField label="admin" source="admin" reference="admins">
                    <FunctionField render={(record) => (
                      <Typography>{record.firstName}&nbsp;{record.lastName}</Typography>
                    )}
                    />
                  </ReferenceField>
                  <DateField source="createdAt" label="Created&nbsp;at" />
                  <DateField source="validFrom" label="Valid&nbsp;from" />
                  <DateField source="validTo" label="Valid&nbsp;to" />
                  <MoneyField source="amountAvailable" label="Available&nbsp;amount" />
                  <MoneyField source="maxValidAmount" label="Max&nbsp;valid&nbsp;amount" />
                  <TextField source="description" label="description" />
                  <DownloadEARDocumentButton accountId={controllerProps.record?.id} />
                </Datagrid>
              </ArrayField>
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <ArrayField label="Whitelisted Payer Name" source="whitelistedPayerNames">
                <Datagrid bulkActionButtons={null}>
                  <DateField source="createdAt" label="Created&nbsp;at" />
                  <TextField source="name" label="name" />
                  <BooleanField source="isTemporary" label="is&nbsp;temporary" />
                  <TextField source="description" label="description" />
                  <DownloadWhitelistedPayerDocumentButton accountId={controllerProps.record?.id} />
                </Datagrid>
              </ArrayField>
            </SimpleShowLabeled>
            <SimpleShowLabeled>
              <TransactionFields label="Transactions" />
            </SimpleShowLabeled>
            <ReportLateInterestButton />
            <SimpleShowLabeled>
              <ArrayField label="Guarantors" source="guarantors">
                <Datagrid bulkActionButtons={null}>
                  <ReferenceField
                    label="Guarantor"
                    source="_id"
                    target="_id"
                    reference="user"
                  >
                    <TextField source="id" label="Guarantor" />
                  </ReferenceField>
                  <TextField source="email" label="Email" />
                  <DateField source="signedAt" label="Signed At" />
                </Datagrid>
              </ArrayField>
            </SimpleShowLabeled>
          </FormTab>
          <InsuranceTab model="account" />
          <FormTab label="Holders Held payments">
            <SimpleShowLabeled>
              <ArrayField source="accountHolders">
                <Datagrid bulkActionButtons={null}>
                  <ReferenceManyField
                    source="_id"
                    label="Held Payments"
                    reference="heldPayments"
                    target="accountHolders._id"
                    pagination={<Pagination />}
                  >
                    <Datagrid bulkActionButtons={null}>
                      <TextField source="id" />
                      <AccountHoldersFields />
                      <ShowButton />
                    </Datagrid>
                  </ReferenceManyField>
                </Datagrid>
              </ArrayField>
            </SimpleShowLabeled>
          </FormTab>
          {getRecord(controllerProps).terms?.articles.length > 0 && (
            <FormTab label="Articles">
              <SimpleShowLabeled>
                <ArrayField source="terms.articles" fieldKey="articleNumber" fullWidth>
                  <Datagrid bulkActionButtons={null}>
                    <TextField source="articleNumber" label="Article Number" />
                    <TextField source="description" />
                    <FunctionField label="Product Category" render={(record) => record && categoryLabel && categoryLabel[record.productCategory]} />
                    <NumberField source="quantity" />
                    <MoneyField source="amount" />
                    <NumberField
                      label="VAT %"
                      source="vatPercent"
                      options={{
                        style: 'unit',
                        unit: 'percent',
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }}
                    />
                  </Datagrid>
                </ArrayField>
              </SimpleShowLabeled>
            </FormTab>
          )}
          <FormTab label="Historical Nominal Rates">
            <SimpleShowLabeled>
              <ArrayField source="terms.nominalRates" label="Historic nominal rates">
                <Datagrid bulkActionButtons={null}>
                  <DateField source="date" label="Nominal Rate Date" />
                  <MoneyField source="nominalRate" label="Nominal Rate" />
                </Datagrid>
              </ArrayField>
            </SimpleShowLabeled>
            <UpdateNominalRateButton />
          </FormTab>
        </TabbedForm>
      </EditView>
    </EditContextProvider>
  );
};

export default AccountEdit;
